const internship_data = [
    
    
    {
        s_no : "01",
        s_name : "SWE Intern",
        s_company : "Lineysha and Thevan Software Technologies",
        s_desc  : "During my final year of diploma studies, I interned at Lineysha and Thevan Technologies as a SWE Intern focusing on Python full stack development. Contributing to a project on Credit Card Fraud Detection System, I enhanced my Python and Django skills while gaining insights into professional work environments. This experience sharpened my problem-solving abilities and provided practical exposure to real-world software development challenges.",
        s_date : "July 2023 - December 2023"
    },
    {
        s_no : "02",
        s_name : "Student Intern",
        s_company : "AICTE and Eduskills - AI-ML Virtual Internship",
        s_desc  : "Thrilled to share that I’ve successfully completed the virtual AI/ML internship provided by Google Developers in collaboration with AICTE and Eduskills! This incredible journey deepened my knowledge in artificial intelligence and machine learning, where I gained hands-on experience with cutting-edge AI/ML tools and technologies from Google. I’m grateful for the opportunity to bridge the gap between academic learning and real-world industry applications in such a forward-thinking environment. My heartfelt thanks to AICTE and Eduskills for their commitment to empowering learners with top-notch tech education. I’m excited to leverage the skills and insights gained in future projects!",
        s_date : "April 2024 - June 2023"
    },
    {
        s_no : "03",
        s_name : "Student Intern",
        s_company : "AICTE and Eduskills - Cloud Virtual Internship",
        s_desc  : "Excited to share that I’ve completed the virtual internship on AWS Cloud, offered by AICTE in collaboration with Eduskills! This immersive experience allowed me to dive deep into cloud computing, mastering essential AWS services and gaining valuable insights into cloud infrastructure management. I’m grateful for the opportunity to enhance my skills in such a dynamic learning environment and proud to be part of a program that bridges the gap between academia and industry. Massive thanks to AICTE and Eduskills for their commitment to empowering students and professionals with cutting-edge technology education. I’m eager to apply the knowledge and skills gained to future endeavors!",
        s_date : "January 2024 - March 2024"
    },
    {
        s_no : "04",
        s_name : "Student Intern",
        s_company : "AICTE and Eduskills - Android Developer Virtual Internship",
        s_desc  : "Proud to have been a student intern at Google, where I embarked on an enriching virtual app development journey, focusing on Google’s innovative Compose framework. Collaborating with a dynamic team, I contributed to creating sleek, user-friendly Android applications, sharpening my skills in UI/UX design and implementation. The virtual environment facilitated global collaboration, allowing me to work alongside talented professionals and mentors. This internship was a holistic experience that nurtured creativity, problem-solving, and effective communication. Grateful for the mentorship and resources, I transformed theoretical knowledge into practical solutions. Thank you, Google, for this incredible opportunity to deepen my understanding of app development and teamwork. A big thanks to AICTE and Eduskills for making this virtual internship possible!",
        s_date : "September 2023 - November 2023"
    },



]

export default internship_data;