import nextskills from './nextskills.png'
import furever_frnd from './furever_frnd.png'
import portfolio_project from './portfolio_project.png'


const project_data = [

    {
        s_no : '01',
        s_name : "Portfolio Project",
        s_subtitle : "Personal Project",
        s_tech : 'React.JS',
        s_link : "https://github.com/2200039088/Portfolio-Website",
        s_img : portfolio_project
    },

    {
        s_no : '02',
        s_name : "NextSkills 360",
        s_subtitle : "Code For Good Hackathon by\nJP Morgan Chase and Co.",
        s_tech : 'MERN Stack',
        s_link : "https://github.com/2200039088/CodeForGood-Hyderbad",
        s_img : nextskills
    },

    {
        s_no : '02',
        s_name : "Pet Adoption Application",
        s_subtitle : "University SDP Hackathon",
        s_tech : 'MERN Stack',
        s_link : "https://github.com/2200039088",
        s_img : furever_frnd
    }

]

export default project_data