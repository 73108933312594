const education_data = [

    {
        s_no : "01",
        s_name : "Vikas Rainbow E.M. High School",
        s_desc : "Secondary School Certificate",
        s_grade : "Grade : 9.7 GPA"
    },

    {
        s_no : "02",
        s_name : "A.A.N.M. and V.V.R.S.R. Polytechnic College",
        s_desc : "Diploma in Computer Engineering",
        s_grade : "Grade : 97%"
    },

    {
        s_no : "03",
        s_name : "KL University",
        s_desc : "B.Tech in CSE",
        s_grade : "Grade : 9.8 CGPA"
    }


]

export default education_data