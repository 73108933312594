import president from './president_rtclub.png'
import jp_group from './jpmorgan_group.png'
import scholar from './scholar_klef.png'

const achievement_data = [
    {
        s_no : "01",
        s_name : "President - Review Tech Club , KL-SAC",
        s_desc : "Served as president for content creating club - Review Tech Under Student Activity Center in KL University. Lead and managed all the club acitivities including organizing meetings, events and workshops related to technology and reviews. Fostered a collaborative environment among club members",
        s_img : president,
        s_link : ''
    },

    {
        s_no : "02",
        s_name : "Participant - One of India's biggest hackathon",
        s_desc : "Shortlisted and participated in Code For Good 2024 organized by JP Morgan Chase and Co. by standing among 86 peers out of 2000 applicants from my university through exceptional coding and problem solving skills",
        s_img : jp_group,
        s_link : 'https://www.linkedin.com/feed/update/urn:li:activity:7210988733553074176/'
    },

    {
        s_no : "01",
        s_name : "Merit Scholar - KLEF",
        s_desc : "Receieved a merit scholarship worth 6.75lakhs from KL Education Foundation by achieving 97% in diploma studies through consistent academic excellence and dedication",
        s_img : scholar,
        s_link : ''
    }

]


export default achievement_data