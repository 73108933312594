import aws_cp from './aws_cp_badge.png'
import aws_sa from './aws_sa_badge.png'
import pcap from './pcap_badge.png'

const certification_data = [
    {
      s_no: '01',
      s_name: 'AWS Certified Cloud Practitioner',
      s_date: 'May 2024',
      s_link: 'https://www.credly.com/badges/49d05947-5f46-4994-9dd9-a8220704c96d/public_url',
      s_img: aws_cp
    },
    {
      s_no: '02',
      s_name: 'AWS Certified Solutions Architect',
      s_date: 'Coming Soon',
      s_link: '',
      s_img: aws_sa
    },
    {
      s_no: '03',
      s_name: 'PCAP Certified Python Programmer',
      s_date: 'Coming Soon',
      s_link: '',
      s_img: pcap
    },
    {
      s_no: '04',
      s_name: 'Show More',
      s_date: '',
      s_link: 'https://www.linkedin.com/in/arepallivineel',  // Replace with your LinkedIn profile URL
      s_img: ''
    }
  ];
  
  export default certification_data;